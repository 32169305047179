import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import FormButton from "component/common/FormButton";
import { useForm } from "react-hook-form";
import { api } from "api/Api";
import {
  GET_UPLOADED_TIMESHEET,
  UPDATE_TIMESHEET,
  UPLOAD_TIMESHEET,
} from "constants/ApiUrls";
import { RESPONSE_OK } from "constants/AppConstants";
import {
  facilityEmailRules,
  timeSheetEndRules,
  timeSheetStartRules,
} from "constants/Rules";
import FormInput from "component/common/FormInput";
import dayjs from "dayjs";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import FormTimePicker from "component/common/FormTimePicker";
import Cookies from "js-cookie";
import { timeZone } from "utils/Utils";

export default function EnterTimeSheetModal({
  modal,
  toggle,
  selectedRow,
  getData,
}) {
  const {
    register,
    trigger,
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [timeSheetLoader, setTimeSheetLoader] = useState(false);
  const [timeSheetData, setTimeSheetData] = useState({});

  useEffect(() => {
    if (selectedRow?.timesheetId) {
      getSheetData();
    }
  }, [selectedRow]);

  useEffect(() => {
    if (modal) {
      reset({
        startTime: "",
        endTime: "",
        description: "",
        breakStartTime: "",
        breakEndTime: "",
      });
    }
  }, [modal, reset]);

  const getSheetData = async () => {
    if (!selectedRow?.timesheetId) {
      return;
    }
    try {
      setTimeSheetLoader(true);
      const res = await api(
        GET_UPLOADED_TIMESHEET,
        {},
        selectedRow.timesheetId,
        null
      );
      if (res.status === RESPONSE_OK) {
        setTimeSheetData(res?.data?.data);
        setTimeSheetLoader(false);
      } else {
        setTimeSheetData({});
        setTimeSheetLoader(false);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const mergeDateAndTime = (date, time) => {
    if (!date || !time) return null;
    const [hours, minutes] = time.split(":");
    const mergedDate = new Date(date);
    mergedDate.setHours(hours);
    mergedDate.setMinutes(minutes);
    mergedDate.setSeconds(0);
    mergedDate.setMilliseconds(0);
    return mergedDate.getTime();
  };

  const submitSheet = async (formData) => {
    const startTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.startDate,
      formData.startTime
    );
    const endTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.endDate,
      formData.endTime
    );
    const breakStartTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.startDate,
      formData.breakStartTime
    );

    const breakEndTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.endDate,
      formData.breakEndTime
    );
    if (
      !formData.startTime ||
      !formData.endTime ||
      !formData.notificationEmail
    ) {
      trigger(["startTime", "endTime", "notificationEmail"]);
      return;
    }
    if (timeSheetData && timeSheetData.isSubmitted === false) {
      let payload = {
        applicationId: selectedRow.id,
        startTime: startTime,
        endTime: endTime,
        notificationEmail: formData.notificationEmail,
        description: formData.description,
        breakStartTime: breakStartTime || null,
        breakEndTime: breakEndTime || null,
        clinicianId: timeSheetData.clinicianId,
        jobAppliedUserId: timeSheetData.id,
        isSubmitted: true,
        timeZone: timeZone(),
      };
      try {
        setLoader(true);
        const res = await api(
          UPDATE_TIMESHEET,
          payload,
          selectedRow.timesheetId,
          null
        );
        if (res.status === RESPONSE_OK) {
          handleToggle();
          getData(false);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error uploading timesheet:", error);
        setLoader(false);
      }
    } else {
      setLoader(true);
      let payload = {
        clinicianId: selectedRow.clinicianId,
        applicationId: selectedRow.id,
        startTime: startTime,
        endTime: endTime,
        notificationEmail: formData.notificationEmail,
        description: formData.description,
        breakStartTime: breakStartTime || null,
        breakEndTime: breakEndTime || null,
        isSubmitted: true,
        timeZone: timeZone(),
      };
      try {
        const res = await api(UPLOAD_TIMESHEET, payload);
        if (res.status === RESPONSE_OK) {
          handleToggle();
          getData(false);
        }
        setLoader(false);
      } catch (error) {
        console.error("Error uploading timesheet:", error);
      }
    }
  };

  const saveSheetData = async (formData) => {
    const startTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.startDate,
      formData.startTime
    );
    const endTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.endDate,
      formData.endTime
    );

    const breakStartTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.startDate,
      formData.breakStartTime
    );

    const breakEndTime = mergeDateAndTime(
      selectedRow?.shiftSlots?.[0]?.endDate,
      formData.breakEndTime
    );
    if (
      !formData.startTime ||
      !formData.endTime ||
      !formData.notificationEmail
    ) {
      trigger(["startTime", "endTime", "notificationEmail"]);
      return;
    }
    if (timeSheetData && timeSheetData.isSubmitted === false) {
      setSaveLoader(true);
      const updatedData = {
        startTime: startTime,
        endTime: endTime,
        notificationEmail: formData.notificationEmail,
        description: formData.description,
        clinicianId: timeSheetData.clinicianId,
        jobAppliedUserId: timeSheetData.id,
        breakStartTime: breakStartTime || null,
        breakEndTime: breakEndTime || null,
        applicationId: selectedRow.id,
        isSubmitted: false,
        timeZone: timeZone(),
      };
      try {
        const res = await api(
          UPDATE_TIMESHEET,
          updatedData,
          selectedRow?.timesheetId,
          null
        );
        if (res.status === RESPONSE_OK) {
          handleToggle();
          getData(false);
        }
        setSaveLoader(false);
      } catch (error) {
        console.error("Error saving timesheet:", error);
        setSaveLoader(false);
      }
    } else {
      setSaveLoader(true);
      let payload = {
        clinicianId: selectedRow.clinicianId,
        applicationId: selectedRow.id,
        startTime: startTime,
        endTime: endTime,
        notificationEmail: formData.notificationEmail,
        description: formData.description,
        breakStartTime: breakStartTime || null,
        breakEndTime: breakEndTime || null,
        isSubmitted: false,
        timeZone: timeZone(),
      };
      try {
        const res = await api(UPLOAD_TIMESHEET, payload);
        if (res.status === RESPONSE_OK) {
          handleToggle();
          getData(false);
        }
        setSaveLoader(false);
      } catch (error) {
        console.error("Error uploading timesheet:", error);
        setSaveLoader(false);
      }
    }
  };
  const onSubmit = (formData) => {
    submitSheet(formData);
  };

  useEffect(() => {
    if (timeSheetData?.id) {
      setValue(
        "notificationEmail",
        timeSheetData.notificationEmail || selectedRow.facilityEmail
      );
      setValue("description", timeSheetData.description);
      setValue(
        "startTime",
        timeSheetData.startTime
          ? dayjs(timeSheetData.startTime)
              .tz(timeSheetData.jobId?.timeZone ?? "America/Chicago")
              .format("HH:mm")
          : ""
      );
      setValue(
        "endTime",
        timeSheetData.endTime
          ? dayjs(timeSheetData.endTime)
              .tz(timeSheetData.jobId?.timeZone ?? "America/Chicago")
              .format("HH:mm")
          : ""
      );
      setValue(
        "breakStartTime",
        timeSheetData.breakStartTime
          ? dayjs(timeSheetData.breakStartTime)
              .tz(timeSheetData.jobId?.timeZone ?? "America/Chicago")
              .format("HH:mm")
          : ""
      );
      setValue(
        "breakEndTime",
        timeSheetData.breakEndTime
          ? dayjs(timeSheetData.breakEndTime)
              .tz(timeSheetData.jobId?.timeZone ?? "America/Chicago")
              .format("HH:mm")
          : ""
      );
    }
  }, [timeSheetData, setValue]);

  const handleToggle = () => {
    setTimeSheetData({});
    toggle();
  };

  return (
    <Modal
      centered
      isOpen={modal}
      toggle={handleToggle}
      className="addLocation-modal"
    >
      <ModalHeader toggle={handleToggle}>Enter Time Sheet</ModalHeader>
      <ModalBody>
        {timeSheetLoader && (
          <div className="centered-spinner">
            <Spinner />
          </div>
        )}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={`d-flex flex-column mb-2 ${
              timeSheetLoader ? "opacity-25" : ""
            }`}
          >
            <FormInput
              trigger={trigger}
              label={"Facility Representative Email:"}
              name={"notificationEmail"}
              register={register}
              inputDivClassName={"textarea"}
              errors={errors}
              rules={facilityEmailRules}
              control={control}
              disabled={timeSheetData && timeSheetData.isSubmitted === true}
            />
            <FormTimePicker
              id={"startTime"}
              name="startTime"
              control={control}
              trigger={trigger}
              register={register}
              errors={errors}
              rules={timeSheetStartRules}
              label="Shift Start:"
              disabled={timeSheetData && timeSheetData.isSubmitted === true}
            />
            <div className="d-flex justify-content-between">
              <div style={{ width: "47%" }}>
                <FormTimePicker
                  id={"breakStartTime"}
                  name="breakStartTime"
                  control={control}
                  trigger={trigger}
                  register={register}
                  errors={errors}
                  rules={{}}
                  label="Break Start:"
                  disabled={timeSheetData && timeSheetData.isSubmitted === true}
                />
              </div>
              <div style={{ width: "47%" }}>
                <FormTimePicker
                  id={"breakEndTime"}
                  name="breakEndTime"
                  control={control}
                  trigger={trigger}
                  register={register}
                  errors={errors}
                  rules={{}}
                  label="Break End:"
                  disabled={timeSheetData && timeSheetData.isSubmitted === true}
                />
              </div>
            </div>
            <FormTimePicker
              id={"endTime"}
              name="endTime"
              control={control}
              trigger={trigger}
              register={register}
              errors={errors}
              rules={timeSheetEndRules}
              label={"Shift End:"}
              disabled={timeSheetData && timeSheetData.isSubmitted === true}
            />
            <FormInput
              trigger={trigger}
              label={"Description:"}
              name={"description"}
              register={register}
              type={"textarea"}
              errors={errors}
              rules={{}}
              control={control}
              disabled={timeSheetData && timeSheetData.isSubmitted === true}
            />
            {selectedRow?.timesheetId ? (
              <h6>Total: {timeSheetData?.totalWorkedHours}</h6>
            ) : (
              ""
            )}
          </div>
          {timeSheetData && timeSheetData.isSubmitted === true ? (
            <div></div>
          ) : (
            <div className="d-flex justify-content-center gap-5">
              <FormButton
                loader={saveLoader}
                className={"pt-btn-small pt-btn btn-primary editexp-btn"}
                type="button"
                label={"Save"}
                onClick={() => {
                  saveSheetData(getValues());
                }}
                disabled={timeSheetData.isSubmitted === true}
              />
              <FormButton
                loader={loader}
                className={"pt-btn-small pt-btn btn-primary editexp-btn"}
                type="submit"
                label={"Submit"}
                disabled={timeSheetData.isSubmitted === true}
              />
            </div>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}
