
import FormError from "./FormError";
import { Controller } from "react-hook-form";
import moment from "moment";
import dayjs from "dayjs";
import { msg } from "constants/messages";
import { TickCalender } from "assets/svg";
export default function FormTimePicker({
  id,
  control,
  errors,
  name,
  rules,
  label,
  trigger,
  errorMsg,
  disabled,
}) {
  return (
    <div className={"form-group"}>
      <label htmlFor={id}>{label}</label>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value, inputRef } }) => (
          <div className={"signup-input"}>
            <input
              type="time"
              style={{
                height: "40px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                width: "100%",
                padding: "0 10px",
              }}
            id={id}
            value={value} 
            onChange={(e) => {
              onChange(e.target.value); 
              trigger(name); 
            }}
            disabled={disabled}
            />
          </div>
        )}
      />
      <FormError msg={errorMsg || errors[name]?.message} />
    </div>
  );
}
